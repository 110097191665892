import React, { useContext } from 'react';


function Home() {

  return (
    <section className="section home" >
      <h1>Seminář z informatiky</h1>
      <div class="subheading">Školní rok 2024/2025</div>
      <div class="subheading">Gymnázium Nad Kavalírkou</div>
      <div class="subheading">Štěpán Kovář</div>
      <h2>Sylabus:</h2>
        <p>Základní pojmy - algoritmus, časová složitost, datová struktura, pseudokód</p>
        <p>Programování v Pythonu</p>
        <p>Jednoduché algoritmické problémy - třídění, vyhledávání, rekurze</p>
        <p>Matematické aplikace v informatice - prvočíselný rozklad, hledání společného dělitele, šifrovací protokol RSA</p>
        <p>Grafy - terminologie, reprezentace, algoritmy</p>
        <p>Známé algoritmické problémy</p>

      
      <h2 class="opredmetu">O předmětu a jak k němu přistupovat</h2>
        
        <p>V tomto předmětu vám představím klíčová témata z informatiky, která by měl každý informatik znát, bez ohledu na svou specializaci (algoritmy, umělá inteligence, vývoj her, vývoj softwaru, …). Pokud se v budoucnu budete zabývat informatikou, znalosti, které zde získáte, se vám určitě budou hodit. Navíc se vás budu snažit vést k trochu jinému způsobu myšlení, což považuji za to nejdůležitější.</p>
        
        <p>Zvolili jste si tento seminář jen proto, že jste nevěděli, co jiného vybrat, a informatika vás tolik neláká? Dejte tomu šanci, možná vás to překvapí a zaujme. Na druhou stranu chápu, že to nemusí být pro každého - třeba zjistíte, že programování není nic pro vás, přemýšlení nad algoritmy vás unavuje, nebo vám nesedne můj styl výuky. To naprosto chápu a respektuji. Je tedy na vás, zda se rozhodnete v hodinách aktivně věnovat tomu, co probíráme, nebo se zaměříte na něco svého, posloucháte hudbu, nebo si třeba odpočinete. Určitě se neurazím. Nejraději bych vám umožnil odejít z hodin a věnovat se tomu, co pro vás má větší smysl, ale školní řád to bohužel neumožňuje. Jak jste si mohli všimnout v pravidlech hodnocení, pokud neodevzdáte žádný úkol, dostanete 4 a ne 5. Pokud moc nepokazíte test, je tedy těžké neprospět. Tuto možnost můžete využít, pokud si to přejete.</p>
        
        <p>Pokud se rozhodnete věnovat semináři více času, budu jedině rád. Nebojte se mi také říct, co by se dalo zlepšit. Jsem tu pro vás, ne vy pro mě. Vy jste moji klienti, já vám poskytuju službu, ne naopak. To ale neznamená, že odpovědnost za vaše učení leží jen na mně. Vzdělávání je obousměrný proces, a proto máte i vy sami odpovědnost za to, jak se učíte a co si z toho odnesete.</p>
    </section>
  );
}

export default Home;
