import React, { useState } from 'react';
import './homework-styles.css';
import { ChevronDown, ChevronUp, Calendar, Award, FileText } from 'lucide-react';

const HomeworkItem = ({ id, title, description, deadline, points, templateLink }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div id={`assignment-${id}`} className="border rounded-lg mb-4 overflow-hidden">
      <div 
        className="flex justify-between items-center p-4 bg-gray-100 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="flex items-center space-x-4">
          <span className="flex items-center">
            <Calendar className="w-4 h-4 mr-1" />
            {deadline}
          </span>
          <span className="flex items-center">
            <Award className="w-4 h-4 mr-1" />
            {points} bodů
          </span>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
      {isExpanded && (
        <div className="p-4">
          <div 
            className="mb-4" 
            dangerouslySetInnerHTML={{ __html: description }} 
          />
          <a 
            href={`/${templateLink}`} // Adding a leading slash here makes it an absolute URL
            download
            target="_blank" 
            rel="noopener noreferrer"
            className="inline-flex items-center text-blue-600 hover:underline"
          >
            <FileText className="w-4 h-4 mr-1 template" />
            Začněte s šablonou
          </a>
        </div>
      )}
    </div>
  );
};

const Ukoly = () => {
  const homeworks = [
    {
      id: 1,
      title: "Úkol 1: Součin prvků až na aktuální",
      description: `
        <p class="description p">Cílem tohoto úkolu je vytvořit funkci <code class="description-code">product_except_self</code>, která přijme seznam celých čísel a vrátí nový seznam, kde každý prvek na indexu i obsahuje součin všech čísel ze vstupního seznamu kromě čísla na indexu i. Při řešení tohoto problému je zakázáno používat operaci dělení.</p>
        <h3 class= "description h3">Popis funkce:</h3>
        <p class="description p">Funkce <code class="description-code">product_except_self</code> by měla projít seznam <code class="description-code">nums</code> a pro každý prvek vypočítat produkt ostatních prvků. Výsledný seznam by měl mít stejnou délku jako vstupní seznam a na každé pozici <code class="description-code">i</code> by měl obsahovat produkt všech prvků seznamu <code class="description-code">nums</code> kromě <code class="description-code">nums[i]</code>.</p>
        <h3 class="description h3">Příklad:</h3>
        <p class="description p">Vstup: <code class="description-code">nums = [1, 2, 3, 4]</code><br>Výstup: <code class="description-code">[24, 12, 8, 6]</code><br>
        V tomto příkladu, pro první prvek (1), výstupní hodnota je součin 2 * 3 * 4 = 24, a tak dále pro ostatní prvky.</p>
        <h3 class="description h3">Asymptotická složitost:</h3>
        <p class="description p">Uveďte v kódu také, v jakém asymptoticky času pracuje váš algoritmus. Nyní se ještě nemusíte snažit, aby váš kód byl co nejefektivnější. Zajímá mě jen  analýza vašeho kódu.</p>
        <h3 class="description h3">Unit Testy:</h3>
        <p class="description p">Po napsání funkce byste měli ověřit její správnost spuštěním připravených unit testů, které ověří funkčnost kódu v různých scénářích.</p>
        <h3 class="description h3">Odevzdání:</h3>
        <p class="description p">Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci <code class="description-code">product_except_self</code> spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.</p>
      `,
      deadline: "29. 9. 2024", 
      points: 5,
      templateLink: "assignments/product_exept_self.py"
    },

    {
      id: 2,
      title: "Úkol 2: Najdi nejbližší větší prvek",
      description: `
        <p class="description p">V tomto úkolu je vaším cílem implementovat funkci <code class="description-code">find_nearest_bigger</code>, která přijme seřazený seznam celých čísel <code class="description-code">arr</code> a celé číslo <code class="description-code">k</code>, a vrátí nejmenší prvek v seznamu, který je větší než <code class="description-code">k</code>. Pokud takový prvek neexistuje, funkce by měla vrátit <code class="description-code">None</code>.</p>
        <h3 class="description h3">Požadavek:</h3>
        <p class="description p">Funkci musíte implementovat pomocí algoritmu binárního vyhledávání, aby dosáhla časové složitosti <code class="description-code">O(log n)</code>. Je zakázáno používat vestavěné funkce pro binární vyhledávání, jako je modul <code class="description-code">bisect</code>.</p>
        <h3 class="description h3">Příklad:</h3>
        <p class="description p">Vstup: <code class="description-code">arr = [1, 3, 5, 7, 9]</code>, <code class="description-code">k = 4</code><br>Výstup: <code class="description-code">5</code></p>
        <h3 class="description h3">Unit Testy:</h3>
        <p class="description p">Po implementaci funkce byste měli ověřit její správnost spuštěním připravených unit testů, které pokrývají různé scénáře. Také otestují, zda je vaše řešení dostatečně efektivní - lineární vyhledávání neprojde.</p>
        <h3 class="description h3">Odevzdání:</h3>
        <p class="description p">Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci <code class="description-code">find_nearest_bigger</code> spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.</p>
      `,
      deadline: "13. 10. 2024", 
      points: 5,
      templateLink: "assignments/nearest_bigger.py"
    }, 

    {
      id: 3,
      title: "Úkol 3: Největší společný dělitel více čísel",
      description: `
        <p class="description p">Cílem tohoto úkolu je vytvořit funkci <code class="description-code">gcd_multiple</code>, která přijme seznam celých čísel a vrátí největší společný dělitel (NSD) všech čísel v seznamu. Vaším úkolem je implementovat Euklidův algoritmus, a zároveň ho modifikovat tak, aby fungoval pro více než dvě čísla.</p>
        
        <h3 class="description h3">Popis funkce:</h3>
        <p class="description p">Funkce <code class="description-code">gcd_multiple</code> přijme seznam celých čísel <code class="description-code">nums</code> a vrátí jediné celé číslo, které je největším společným dělitelem všech čísel v seznamu. Vaším cílem je navrhnout efektivní způsob, jak upravit Euklidův algoritmus tak, aby fungoval pro libovolný počet čísel. Můžete začít výpočtem NSD dvou čísel a tento výsledek následně použít k výpočtu NSD s dalšími čísly.</p>
    
        <h3 class="description h3">Příklad:</h3>
        <p class="description p">Vstup: <code class="description-code">nums = [60, 48, 36]</code><br>Výstup: <code class="description-code">12</code><br>
        V tomto příkladu, největším společným dělitelem čísel 60, 48 a 36 je 12.</p>
    
        <h3 class="description h3">Asymptotická složitost:</h3>
        <p class="description p">Uveďte v kódu také, v jakém asymptoticky času pracuje váš algoritmus. Zaměřte se na to, jak roste časová náročnost s počtem čísel a velikostí vstupních hodnot.</p>
    
        <h3 class="description h3">Unit Testy:</h3>
        <p class="description p">Po napsání funkce ověřte její správnost spuštěním připravených unit testů, které ověří funkčnost kódu pro různé vstupy. Váš kód by měl projít všemi testy, než ho odevzdáte.</p>
    
        <h3 class="description h3">Odevzdání:</h3>
        <p class="description p">Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci <code class="description-code">gcd_multiple</code> spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.</p>
      `,
      deadline: "20. 10. 2024", 
      points: 5,
      templateLink: "assignments/gcd_multiple.py"
    },

    {
      id: 4,
      title: "Úkol 4: Seřazení lichých a sudých čísel",
      description: `
        <p class="description p">Cílem tohoto úkolu je vytvořit funkci <code class="description-code">sort_odd_even</code>, která přijme seznam celých čísel a seřadí jej následujícím způsobem: lichá čísla budou seřazena vzestupně, zatímco sudá čísla sestupně. Funkce zachová původní relativní pozice lichých a sudých čísel v seznamu.</p>
        
        <h3 class="description h3">Popis funkce:</h3>
        <p class="description p">Funkce <code class="description-code">sort_odd_even</code> přijme seznam celých čísel <code class="description-code">nums</code> a vrátí seznam, ve kterém jsou lichá čísla seřazena vzestupně a sudá čísla sestupně. Relativní pozice čísel (lichých i sudých) vůči jejich původnímu pořadí zůstávají zachovány. Budete muset implementovat třídicí algoritmus a zároveň modifikovat jeho část, aby správně třídil sudá čísla sestupně.</p>
        <p class="description p"> <b>Nepoužívejte prosím žádné built-in třídicí algoritmy - sort() atp.</b></p>
        
        <h3 class="description h3">Příklad:</h3>
        <p class="description p">Vstup: <code class="description-code">nums = [5, 8, 3, 2, 9, 6, 4, 7]</code><br>Výstup: <code class="description-code">[3, 8, 5, 6, 7, 4, 2, 9]</code><br>
        V tomto příkladu jsou lichá čísla (5, 3, 9, 7) seřazena vzestupně na <code class="description-code">[3, 5, 7, 9]</code> a sudá čísla (8, 2, 6, 4) sestupně na <code class="description-code">[8, 6, 4, 2]</code>.</p>
        
        <h3 class="description h3">Asymptotická složitost:</h3>
        <p class="description p">Uveďte v kódu, v jakém asymptoticky času pracuje váš algoritmus. Zaměřte se na to, jak se časová složitost mění s velikostí seznamu a volbou třídicího algoritmu.</p>
        
        <h3 class="description h3">Unit Testy:</h3>
        <p class="description p">Po napsání funkce ověřte její správnost spuštěním připravených unit testů, které ověří funkčnost kódu pro různé vstupy. Váš kód by měl projít všemi testy, než ho odevzdáte.</p>
        
        <h3 class="description h3">Odevzdání:</h3>
        <p class="description p">Odevzdejte své řešení ve formě souboru Pythonu, který obsahuje funkci <code class="description-code">sort_odd_even</code> spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.</p>
      `,
      deadline: "3. 11. 2024", 
      points: 5,
      templateLink: "assignments/sort_odd_even.py"
    },

    {
      "id": 5,
      "title": "Úkol 5: Merge sort s top k prvky",
      "description": `
        <p class="description p">V tomto úkolu budete implementovat funkci <code class="description-code">merge_sort_top_k</code>, která seřadí pole pomocí algoritmu merge sort a vrátí <code class="description-code">k</code> největších prvků.</p>
    
        <h3 class="description h3">Popis funkce:</h3>
        <p class="description p">Funkce <code class="description-code">merge_sort_top_k</code> přijme seznam celých čísel <code class="description-code">arr</code> a celé číslo <code class="description-code">k</code>. Vaším úkolem je implementovat třídicí algoritmus merge sort a vrátit <code class="description-code">k</code> největších prvků z <code class="description-code">arr</code>. Algoritmus merge sort třídí pole s asymptotickou složitostí <code class="description-code">O(n log n)</code>.</p>
        
        <h3 class="description h3">Příklad:</h3>
        <p class="description p">Vstup: <code class="description-code">arr = [3, 1, 5, 6, 8, 2, 9, 4, 7]</code>, <code class="description-code">k = 3</code><br>Výstup: <code class="description-code">[9, 8, 7]</code><br>
        V tomto příkladu funkce vrátí tři největší prvky v seznamu, tedy 9, 8 a 7.</p>
        
        <h3 class="description h3">Unit Testy:</h3>
        <p class="description p">Součástí úkolu jsou připravené unit testy, které otestují funkčnost vašeho kódu pro různé vstupy. Unit testy také zkontrolují efektivnost vaší implementace, takže by váš kód měl nejen správně fungovat, ale být i efektivní, aby prošel testy výkonnosti.</p>
    
        <h3 class="description h3">Odevzdání:</h3>
        <p class="description p">Odevzdejte své řešení jako soubor Pythonu, který obsahuje funkci <code class="description-code">merge_sort_top_k</code> spolu s testy. Ujistěte se, že váš kód úspěšně prochází všemi testy před odevzdáním. Odevzdejte do Teams.</p>
      `,
      "deadline": "11. 11. 2024",
      "points": 5,
      "templateLink": "assignments/merge_sort_top_k.py"
    },

{
  "id": 6,
  "title": "Úkol 6: Extrakce maxima z MaxHeapu",
  "description": `
    <p class="description p">V tomto úkolu budete implementovat metodu <code class="description-code">extract_max</code> pro třídu <code class="description-code">MaxHeap</code>. Tato metoda vrátí maximální prvek haldy a upraví její strukturu tak, aby stále odpovídala pravidlům maximové haldy.</p>

    <h3 class="description h3">Popis funkce:</h3>
    <p class="description p">Metoda <code class="description-code">extract_max</code> by měla odstranit a vrátit maximální prvek v haldě (prvek na vrcholu) a přeuspořádat zbytek haldy tak, aby zůstala v maximovém uspořádání. Po odstranění maximálního prvku je třeba zajistit, aby nový kořen haldy správně "probublal" směrem dolů, aby se obnovilo haldové uspořádání.</p>
    
    <h3 class="description h3">Příklad:</h3>
    <p class="description p">Předpokládejme, že máme následující MaxHeap reprezentovaný polem: <code class="description-code">[20, 10, 15, 8, 9, 5, 12]</code>.<br>
    Po zavolání <code class="description-code">extract_max</code> by se měl vrátit prvek <code class="description-code">20</code> a halda by měla být upravena na nové maximové uspořádání.</p>
    
    <h3 class="description h3">Unit Testy:</h3>
    <p class="description p">Součástí úkolu jsou připravené unit testy, které otestují správnost vaší implementace na různých případech, včetně prázdné haldy, haldy s jedním prvkem a komplexnějších struktur haldy. Ujistěte se, že všechny testy prochází, abyste si byli jistí správností implementace.</p>

    <h3 class="description h3">Odevzdání:</h3>
    <p class="description p">Odevzdejte své řešení jako soubor Pythonu obsahující třídu <code class="description-code">MaxHeap</code> s implementovanou metodou <code class="description-code">extract_max</code>. Ujistěte se, že váš kód úspěšně prochází všemi unit testy před odevzdáním. Odevzdejte do Teams.</p>
  `,
  "deadline": "24. 11. 2024",
  "points": 10,
  "templateLink": "assignments/extract_max.py"
}
  ];

  return (
    <section id="ukoly" className="section p-6">
      <h2 className="text-2xl font-bold mb-6">Úkoly</h2>
      <h3 className="description h3">Pravidla:</h3>
      <ul>
        <li> Úkoly můžete odevzdávat do 23:59 v den odevzdání. Kdyby se ale cokoli dělo (nad něčím jste se zasekli, jste u babičky atd.), rád vám deadline prodloužím. Platí však, že mi o tom musíte napsat před deadlinem. </li>
        <li> Na úkolech můžete spolupracovat a bavit se o jejich řešení, ale platí, že kód píše každý sám. Ne společně. </li>
        <li> Všechny internetové zdroje jsou samozřejmě povoleny. </li>
        <li> Generativní AI modely vám sice zakázat nemohu (ani nemám jak), ale doporučuji vám je nepoužívat. Půjde o základní a zásadní koncepty, které je opravdu dobré si osvojit vlastními silami. </li>
      </ul>

      <div className="space-y-4">
        {homeworks.map((homework) => (
          <HomeworkItem key={homework.id} {...homework} />
        ))}
      </div>
    </section>
  );
};

export default Ukoly;
